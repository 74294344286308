<template>
  <div class="scroll-page">
    <Fixed :navFixed="navFixed"></Fixed>
    <div class="scroll-titleBox" id="scroll-titleBox">
      <p style="font-size:27px; line-height:200%; margin:0pt 0pt 10pt;text-align: center;"><a
        name="_055-1599121612466"></a><span
        style="font-size:27px; font-weight:bold">移动智能终端生态联盟章程</span></p>
    </div>
    <div class="scroll-contentBox">
      <h1 class="h1 text-center ">
        移动智能终端生态联盟章程
      </h1>
      <h2 class="h2 margin-t30 margin-b20 nav-title" id="navContent0">
        第一章 总则
      </h2>
      <p class="p margin-l20 margin-b20">
        第一条 本联盟的全称为移动智能终端生态联盟，简称金标联盟，英文缩写：ITGSA。本联盟是在自愿、平等、互利合作的基础上，由（以下顺序按公司名称首字母顺序排列）阿里巴巴网络技术有限公司（以下简称“阿里”）、百度在线网络技术（北京）有限公司（以下简称“百度“）、广东小天才科技有限公司（以下简称“小天才”）、联想集团有限公司（以下简称“联想”）、OPPO广东移动通信有限公司（以下简称“OPPO”）、深圳市腾讯计算机系统有限公司（以下简称“腾讯”）、维沃移动通信有限公司（以下简称“vivo”）、小米科技有限责任公司（以下简称“小米“）等自愿联合发起成立的开放性、非营利性的社会组织。
      </p>
      <p class="p margin-l20 margin-b20">
        第二条 本联盟旨在以国家产业政策为导向，以市场为驱动，以服务开发者企业为目的，制定技术标准，搭建智能终端生态平台，提升联盟成员的生态研发、设计、集成、服务等水平，探索和构建智能终端产业的下一代生态，强化我国智能终端产业的竞争力，促进技术进步、提高生产效率，支持新技术、新产业、新业态、新模式的发展。
      </p>
      <p class="p margin-l20 margin-b20">
        第三条 本联盟及其会员应本着守法、诚信、合规的原则参与本联盟的一切事务，积极建设合规的市场竞争环境。
      </p>
      <p class="p margin-l20 margin-b20">
        第四条 本联盟接受上级单位的业务指导和监督管理。
      </p>
      <p class="p margin-l20 margin-b20">
        第五条 本联盟秘书处设在【深圳】。
      </p>
      <h2 class="h2 margin-t30 margin-b20 nav-title" id="navContent1">
        第二章 联盟任务与主要工作
      </h2>
      <p class="p margin-l20 margin-b20">
        第六条 本联盟基于技术标准的制定，搭建智能终端设备的生态平台，以服务上下游生态企业、支撑政府决策、推进智能终端产业发展。
      </p>
      <p class="p margin-l20 margin-b20">
        第七条 本联盟任务是着力聚集产业生态各方力量，联合开展智能终端的技术标准生态研究，共同探索在联盟技术标准生态下的新技术、新模式和新机制。推进技术、产业与应用研发，开展试点示范、产业合作，形成智能终端生态的服务平台。
      </p>
      <p class="p margin-l20 margin-b10">
        第八条 联盟主要工作内容：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）开展智能终端生态涉及的技术标准、政策法律、产业预研、安全合规、知识产权等领域的研究。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）开展智能终端生态涉及的技术标准的前期研究及标准探索、标准制定、标准发布，有力推动移动行业的高水平发展及业务创新。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）对符合联盟技术标准的生态企业进行【金标认证】与技术交流。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （四）品牌推广：通过重大活动、网站、专业媒体等形式拓展联盟品牌以及影响力。
      </p>
      <p class="p-small margin-l30 margin-b20">
        （五）其他联盟相关工作。
      </p>
      <h2 class="h2 margin-t30 margin-b20 nav-title" id="navContent2">
        第三章 联盟会员及其权利与义务
      </h2>
      <p class="p margin-l20 margin-b20">
        第九条 本联盟由会员单位组成，会员包括：理事长成员、理事单位成员、生态单位、合作伙伴。
      </p>
      <p class="p margin-l20 margin-b10">
        第十条 申请加入本联盟的会员，必须具备以下条件：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）经行政主管部门正式批准成立的企/事业单位、协议组织；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）拥护本联盟的章程，有加入本联盟的意愿；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）在本联盟的技术标准生态领域内具有一定的影响力；
      </p>
      <p class="p-small margin-l30 margin-b20">
        （四）履行会员义务，积极参加活动。
      </p>
      <p class="p margin-l20 margin-b10">
        第十一条 申请加入联盟会员程序：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）下载、填写申请加入联盟材料：《移动智能终端生态联盟新会员申请表》；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）申请人将《移动智能终端生态联盟新会员申请表》电子版发送至联盟秘书处进行审核；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）通过审核的申请单位，即可将《移动智能终端生态联盟新会员申请表》纸质版材料签字、盖章后提交联盟秘书处；
      </p>
      <p class="p-small margin-l30 margin-b20">
        （四）成为联盟正式会员后，可享受会员相关权利并履行相关义务，可以自愿申请加入联盟技术工作组。
      </p>
      <p class="p margin-l20 margin-b10">
        第十二条 理事长成员
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）在联盟内作出贡献的理事单位成员、生态单位、合作伙伴，可由理事长成员提名，经理事长全票通过即可成为理事长成员。新增的理事长成员任期三年，可以连选连任。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）理事长成员享有下列权利：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 参与讨论并决策联盟的战略方向、重大事项；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 在联盟官网/官方材料展示会员单位LOGO；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 提议成立联盟下设的工作组和重大立项；
      </p>
      <p class="p-small margin-l50 margin-b10">
        4. 联盟会员单位可在其自有平台及产品相应宣传页面展示本联盟LOGO，但不可用于本章程约定之外的商业用途；
      </p>
      <p class="p-small margin-l50 margin-b10">
        5. 拥有联盟秘书处和工作组负责人的选举权、被选举权和表决权；
      </p>
      <p class="p-small margin-l50 margin-b10">
        6. 拥有联盟技术标准立项权、起草权及投票权；
      </p>
      <p class="p-small margin-l50 margin-b10">
        7. 联盟资源共享。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）理事长成员履行下列义务：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 出席理事长会议，执行理事长决议；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 在职责范围内行使权利，不越权；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 不利用理事职权谋取不正当利益；
      </p>
      <p class="p-small margin-l50 margin-b10">
        4. 不从事损害本团体合法利益的活动；
      </p>
      <p class="p-small margin-l50 margin-b10">
        5. 不得泄露涉及本团体的保密信息，但法律、法规另有规定的除外;
      </p>
      <p class="p-small margin-l50 margin-b10">
        6. 谨慎、认真、勤勉、独立行使被合法赋予的职权；
      </p>
      <p class="p-small margin-l50 margin-b20">
        7. 遵守联盟关于数据使用、隐私与个人信息保护的规定，具体请参见<a href="https://www.itgsa.com/privacy">《ITGSA数据使用、隐私与个人信息保护协议》</a>的内容。
      </p>
      <p class="p margin-l20 margin-b10">
        第十三条 理事单位
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）承认本章程的会员单位，提出申请，经理事长成员全体投票通过后，即可成为理事单位。理事单位任期2年，当上述任期届满时，经理事长成员1/2表决通过后，可连任。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）理事单位享有下列权利：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 参与讨论联盟的战略方向、重大事项；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 在联盟官网/官方材料展示会员单位LOGO；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 联盟会员单位可在其自有平台及产品的相应宣传页面展示本联盟LOGO，但不可用于本章程约定之外的商业用途。可参与联盟秘书处工作；
      </p>
      <p class="p-small margin-l50 margin-b10">
        4. 参与联盟下设的工作组或项目；
      </p>
      <p class="p-small margin-l50 margin-b10">
        5. 可参与联盟技术标准制定；
      </p>
      <p class="p-small margin-l50 margin-b10">
        6. 拥有联盟秘书处和工作组负责人被选举权；
      </p>
      <p class="p-small margin-l50 margin-b20">
        7. 联盟内公开资源共享；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）理事单位履行下列义务：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 遵守联盟章程、执行联盟决议；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 全力维护联盟的权益和声誉，不得以联盟名义从事有损行业声誉和侵害客户利益的活动；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 积极参加联盟组织的各类活动，并给予必要的支持和帮助；
      </p>
      <p class="p-small margin-l50 margin-b10">
        4. 不得泄露涉及本团体的保密信息，但法律、法规另有规定的除外；
      </p>
      <p class="p-small margin-l50 margin-b10">
        5. 指定专人负责同联盟秘书处联系，以利开展日常工作；
      </p>
      <p class="p-small margin-l50 margin-b10">
        6. 遵守联盟关于数据使用、隐私与个人信息保护的规定，具体请参见<a href="https://www.itgsa.com/privacy">《ITGSA数据使用、隐私与个人信息保护协议》</a>的内容。
      </p>
      <p class="p margin-l20 margin-b10">
        第十四条 生态单位
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）认可联盟发布的技术标准，完成联盟官网注册，满足【第十条】会员必备条件，并且完成【第十一条】会员申请，即可成为生态单位。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）生态单位享有下列权利：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 可在联盟官网展示LOGO；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 可参与联盟下设的工作组或项目；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 可参与联盟技术标准制定；
      </p>
      <p class="p-small margin-l50 margin-b10">
        4. 联盟内公开资源共享。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）生态单位履行下列义务：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 遵守联盟章程、执行联盟决议；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 全力维护联盟的权益和声誉，不得以联盟名义从事有损行业声誉和侵害客户利益的活动；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 积极参加联盟组织的各类活动，并给予必要的支持和帮助；
      </p>
      <p class="p-small margin-l50 margin-b10">
        4. 不得泄露涉及本团体的保密信息，但法律、法规另有规定的除外；
      </p>
      <p class="p-small margin-l50 margin-b10">
        5. 遵守联盟关于数据使用、隐私与个人信息保护的规定，具体请参见<a href="https://www.itgsa.com/privacy">《ITGSA数据使用、隐私与个人信息保护协议》</a>的内容。
      </p>
      <p class="p-small margin-l50 margin-b10">
        6. 理解并同意，联盟审核会员及其产品的行为并不免除会员对上传内容的合法、不侵权等的担保责任，同样并不意味着联盟对其审核结果承担任何法律责任，包括对会员的资质、其产品承担任何保证或法律责任，会员须对其上传的产品、内容负全部责任。
      </p>
      <p class="p margin-l20 margin-b10">
        第十五条 合作伙伴
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）认可联盟发布的技术标准，完成联盟官网注册，满足【第十条】会员必备条件，并且完成【第十一条】会员申请，即可成为合作伙伴。
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）合作伙伴享有下列权利：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 可在联盟官网展示LOGO；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 可参与联盟下设的工作组或项目；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 可参与联盟技术标准制定；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）合作伙伴履行下列义务：
      </p>
      <p class="p-small margin-l50 margin-b10">
        1. 遵守联盟章程、执行联盟决议；
      </p>
      <p class="p-small margin-l50 margin-b10">
        2. 全力维护联盟的权益和声誉，不得以联盟名义从事有损行业声誉和侵害客户利益的活动；
      </p>
      <p class="p-small margin-l50 margin-b10">
        3. 积极参加联盟组织的各类活动，并给予必要的支持和帮助；
      </p>
      <p class="p-small margin-l50 margin-b10">
        4. 不得泄露涉及本团体的保密信息，但法律、法规另有规定的除外；
      </p>
      <p class="p-small margin-l50 margin-b10">
        5. 遵守联盟关于数据使用、隐私与个人信息保护的规定，具体请参见<a href="https://www.itgsa.com/privacy">《ITGSA数据使用、隐私与个人信息保护协议》</a>的内容。
      </p>
      <p class="p-small margin-l50 margin-b20">
        6. 理解并同意，联盟审核会员及其产品的行为并不免除会员对上传内容的合法、不侵权等的担保责任，同样并不意味着联盟对其审核结果承担任何法律责任，包括对会员的资质、其产品承担任何保证或法律责任，会员须对其上传的产品、内容负全部责任。
      </p>
      <p class="p margin-l20 margin-b10">
        第十六条 会员有义务向联盟披露其提供的产品及服务的详细信息，会员向联盟提供的内容应当满足所有可适用的出口管制法律法规要求。
      </p>
      <p class="p margin-l20 margin-b10">
        第十七条 会员单位退会及除名
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）会员退会自由，退会时应书面通知联盟秘书处，经理事长成员讨论通过后，履行有关手续，终止会员资格；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）会员单位因重大违法违纪被其它行政管理部门取消经营许可，则其会员资格自动取消；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）会员退出或被取消会员资格，已经提供的活动经费不予退还；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （四）会员如有严重违反本章程的行为，经理事长表决通过，予以除名。
      </p>
      <h2 class="h2 margin-t30 margin-b20 nav-title" id="navContent3">
        第四章 联盟组织架构
      </h2>
      <p class="p margin-l20 margin-b20">
        第十八条 联盟组织架构包括理事长、技术标准工作组、职能工作组，理事长下设秘书处。
      </p>
      <p class="p margin-l20 margin-b10">
        第十九条 联盟理事长由联盟理事长成员组成，联盟理事长是联盟的最高权力机构，其主要职责如下：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）审议、批准和修改联盟章程；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）审议联盟发展规划和工作方针；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）审议联盟的工作报告；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （四）审议决定联盟技术标准的发布；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （五）决定联盟的变更和终止；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （六）任命和罢免秘书处和工作组负责人；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （七）负责组织召开理事长会议和会员大会等会议；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （八）决定名誉职务人选；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （九）决定会员的纳入或除名；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （十）决定联盟工作组和项目的设定；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （十一）监督联盟资金使用、项目实施；
      </p>
      <p class="p-small margin-l30 margin-b20">
        （十二）决议其他重大事宜。
      </p>
      <p class="p margin-l20 margin-b20">
        第二十条 各联盟理事长成员单位可指派1位委托人（自然人）担任联盟理事长代表，代表本联盟理事长成员单位行使理事长成员权利，履行理事长成员义务，联盟成员单位任命、变更联盟理事长代表或其权限的，应当出具书面任命书或证明函。
      </p>
      <p class="p margin-l20 margin-b20">
        第二十一条 荣誉理事长：荣誉理事长由联盟理事长成员单位投票选举产生，任期2年，除本章程另有规定外，连选连任。
      </p>
      <p class="p margin-l20 margin-b20">
        第二十二条 理事长须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。理事不能出席理事长的，可书面委托一名代表出席，并有委托投票权。理事长原则上每年至少召开一次。
      </p>
      <p class="p margin-l20 margin-b10">
        第二十三条 本联盟设立秘书处作为日常办事机构， 其主要职责如下：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）执行理事长各项决议，负责组织、管理、协调联盟的各项工作；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）负责筹备理事长会议和会员大会等会议的召开；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）起草联盟工作计划，报理事长审议；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （四）起草联盟年度工作报告，定期向理事长汇报；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （五）负责联盟成员加入与退出申请的受理；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （六）拟订内部管理制度，报理事长批准；
      </p>
      <p class="p-small margin-l30 margin-b20">
        （七）处理其他日常事务。
      </p>
      <p class="p margin-l20 margin-b20">
        第二十四条 秘书处成员可由理事长推荐产生，或由会员单位向理事长申请，经理事长成员过半数表决通过后，向全体会员公示。
      </p>
      <p class="p margin-l20 margin-b20">
        第二十五条 秘书长应当由联盟理事长派代表担任，秘书长人选由理事长成员单位提名，经全部理事长成员和理事单位成员推选产生；经理事长成员单位提名，获理事长成员单位半数以上表决通过的联盟理事长代表，为秘书长有效候选人，理事长成员和理事单位成员推选票数最多的有效候选人当选联盟秘书处秘书长；联盟秘书处常设秘书长1名，秘书长任期2年，连选连任。
      </p>
      <p class="p margin-l20 margin-b10">
        第二十六条 技术标准工作组主要职责如下：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）负责研究、制定相关技术标准；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）起草工作组计划，报理事长审议；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）负责工作组会议的筹备和召开；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （四）对本工作组的标准进度和质量负责，在标准立项、审查等各环节，应严格把关；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （五）起草工作组工作报告，定期向理事长汇报；
      </p>
      <p class="p-small margin-l30 margin-b20">
        （六）处理其他日常事务。
      </p>
      <p class="p margin-l20 margin-b20">
        第二十七条 技术标准工作组成员可由理事长推荐产生，或由会员单位向理事长申请，经理事长成员过半数表决通过后，向全体会员公示。标准工作的决策、投票、立项等事项，成员单位需指定一名代表参与标准工作组的决策投票。标准制定工作经过到会理事2/3以上表决通过方能生效。
      </p>
      <p class="p margin-l20 margin-b10">
        第二十八条 职能工作组主要职责如下：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）负责联盟推广、活动策划组织、信息传播；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）起草工作组计划，报理事长审议；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （三）负责工作组会议的筹备和召开；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （四）对本工作组的项目进度和质量负责；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （五）起草职能组工作报告，定期向理事长汇报；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （六）处理其他日常事务。
      </p>
      <p class="p margin-l20 margin-b20">
        第二十九条 职能工作组成员可由理事长推荐产生，或由会员单位向理事长申请，经理事长成员过半数表决通过后，向全体会员公示。
      </p>
      <h2 class="h2 margin-t30 margin-b20 nav-title" id="navContent4">
        第五章 资产管理和使用原则
      </h2>
      <p class="p margin-l20 margin-b20">
        第三十条 本联盟经费由联盟理成员以会费的形式进行分担，用于支付联盟日常运维费用、线下活动费用及其他联盟相关事务所需费用，联盟经费按照《移动智能终端生态联盟经费管理办法》执行。
      </p>
      <p class="p margin-l20 margin-b20">
        第三十一条 本联盟经费必须用于本章程规定的业务范围和事业的发展，不得在会员中分配。
      </p>
      <p class="p margin-l20 margin-b20">
        第三十二条 本联盟经费由联盟秘书处统一管理、专款专用，不得使用在联盟规定的范围以外，接受联盟理事长监督。
      </p>
      <p class="p margin-l20 margin-b20">
        第三十三条 本联盟的资产，任何单位、个人不得侵占、私分和挪用。
      </p>
      <p class="p margin-l20 margin-b20">
        第三十四条 本联盟活动中形成的知识产权、标准、成果等，其归属、使用，基于国家有关规定,按照《移动智能终端生态联盟知识产权管理办法》执行。
      </p>
      <h2 class="h2 margin-t30 margin-b20 nav-title" id="navContent5">
        第六章 终止程序和终止后的资产处理
      </h2>
      <p class="p margin-l20 margin-b20">
        第三十五条 联盟完成宗旨或自行解散，由理事长提前一年提出终止协议。
      </p>
      <p class="p margin-l20 margin-b20">
        第三十六条 联盟终止须经会员大会表决通过，并报业务主管单位审查同意。
      </p>
      <p class="p margin-l20 margin-b10">
        第三十七条 在联盟存续期间，有下列情形之一的，理事长成员可以退出联盟：
      </p>
      <p class="p-small margin-l30 margin-b10">
        （一）理事长成员书面通知联盟秘书处退出联盟的；
      </p>
      <p class="p-small margin-l30 margin-b10">
        （二）理事长成员连续12个月不参加联盟活动，视为自动退出；
      </p>
      <p class="p-small margin-l30 margin-b20">
        （三）发生理事长成员难以继续参加联盟事由的。
      </p>
      <h2 class="h2 margin-t30 margin-b20 nav-title" id="navContent6">
        第七章 附则
      </h2>
      <p class="p margin-l20 margin-b20">
        第三十八条 本章程经理事长全部成员通过后生效。
      </p>
      <p class="p margin-l20 margin-b20">
        第三十九条 本章程的最终解释权属移动智能终端生态联盟理事长。
      </p>
    </div>
  </div>
</template>

<script>
import Fixed from "@/components/Fixed";
export default {
  components: { Fixed },
  data() {
    return {
      navFixed: [
        {
          name:'第一章 : 总则',
          isActive:0,
          class:'#navContent0',
          child:[]
        },
        {
          name:'第二章 : 联盟任务与主要工作',
          isActive:null,
          class:'#navContent1',
        },
        {
          name:'第三章 : 联盟会员及其权利与义务',
          isActive:null,
          class:'#navContent2',
        },
        {
          name:'第四章 : 联盟组织架构',
          isActive:null,
          class:'#navContent3',
        },
        {
          name:'第五章 : 资产管理和使用原则',
          isActive:null,
          class:'#navContent4',
        },
        {
          name:'第六章 : 终止程序和终止后的资产处理',
          isActive:null,
          class:'#navContent5',
        },
        {
          name:'第七章 : 附则',
          isActive:null,
          class:'#navContent6',
        },
        
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.scroll-page {
  height: 100%;
  .scroll-titleBox{ 
    position: fixed;
    top: 80px;
    width: 100%;
    padding-top: 20px;
    border-bottom: 1px solid #dde2e8;
    background: #f8f6f7;
    z-index: 6;
  }
  .scroll-contentBox{
    width:calc(100%-400px) ;
    border-left: 1px solid #dde2e8;
    padding: 2% 3% 2% 2%;
    margin-left: 300px;
    .a-hover{
      color: #1a53ff;
    }
    .a-hover:hover{
      opacity: 0.7;
    }
  }
}
@media screen and (max-width: 800px) {
    .scroll-page {
      .scroll-titleBox{ 
        position: fixed;
        top: 120px;
        width: 100%;
        padding-top: 20px;
        border-bottom: 1px solid #dde2e8;
        background: #f8f6f7;
        z-index: 6;
      }
      .scroll-contentBox{
        box-sizing:border-box; 
        width:100%;
        padding:120px 8% 2% 5%;
        border-left:0;
        margin-top:100px;
        margin-left:0px;
      }
    }
}

.h1 {
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 20px;
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  font-weight: bold;
}
.p {
  display: block;
  font-size: 15px;
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  line-height: 24px;
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>

